import React, { useState, ChangeEvent, FC, useEffect } from "react";
import classNames from "classnames";
import { ArrowUpTrayIcon, DocumentIcon } from "@heroicons/react/24/outline";
import { humanFileSize } from "~/lib/utils";
import { Format, getFormatByExtension } from "~/lib/format";
import { listen } from "@tauri-apps/api/event";
import { open } from "@tauri-apps/api/dialog";

type DomFile = globalThis.File;

export type FileSource =
  | {
      type: "file";
      file: DomFile;
    }
  | {
      type: "local-file";
      path: string;
    }
  | {
      type: "url";
      url: string;
    }
  | {
      type: "rawText";
      text: string;
    };

type UrlUploadState = {
  url: string;
};

export function formatFromFileSource(source: FileSource): Format | null {
  let extension: string | null = null;
  if (source.type === "url") {
    const urlParts = source.url.split(".");
    extension = urlParts[urlParts.length - 1];
  } else if (source.type === "file") {
    const fileParts = source.file.name.split(".");
    extension = fileParts[fileParts.length - 1];
  } else if (source.type === "local-file") {
    const fileParts = source.path.split(".");
    extension = fileParts[fileParts.length - 1];
  }

  if (extension) {
    return getFormatByExtension(extension);
  }

  return null;
}

type FileUploadState = { file: DomFile | null };

type SelectFileState = {
  fileUpload: FileUploadState;
  localFile: string | null;
  urlUpload: UrlUploadState;
  rawText: { text: string };
};

interface TabButtonProps<T> {
  id: T;
  title: string;
  currentTab: T;
  setTab: (tab: T) => void;
}

function TabButton<T>({ id, title, currentTab, setTab }: TabButtonProps<T>) {
  return (
    <button
      onClick={() => setTab(id)}
      className={`py-2 px-6 text-sm font-medium text-left md:text-center rounded-lg ${
        currentTab === id
          ? "bg-primary-50/60 text-primary"
          : "text-gray-500 hover:bg-gray-50"
      } focus:outline-none`}
    >
      {title}
    </button>
  );
}

function LocalFile({ onSelectFile }: { onSelectFile: (file: string) => void }) {
  const [isDragging, setIsDragging] = useState(false);
  const [path, setPath] = useState<string | null>(null);

  React.useEffect(() => {
    const unslisten = listen("tauri://file-drop", (event) => {
      const payload = event.payload as string[];
      const selectedPath = payload[0];

      onSelectFile(selectedPath);
      setPath(selectedPath);
    });

    return () => {
      unslisten.then((u) => u());
    };
  }, []);

  const boxStyles =
    "block w-full px-6 relative bg-primary-50/50 border-2 border-primary-100 hover:border-primary-300 rounded-lg min-h-[200px] cursor-pointer flex flex-col items-center justify-center text-center text-primary-300 hover:bg-primary-50 hover:text-primary-800 text-xl border-dashed hover:transition break-all";

  const innerContent =
    path !== null ? (
      <div>
        <div className="block">
          <span>{path}</span>
        </div>
      </div>
    ) : (
      <div>
        <div className="text-center pb-4 w-full">
          <span className="text-left text-sm">
            <DocumentIcon className="inline-block w-8 h-8 mr-1" />
          </span>
        </div>
        <span className="block p-4">Drop a file or click to select a file</span>
      </div>
    );

  return (
    <button
      onClick={() => {
        open({
          directory: false,
          multiple: false,
        }).then((result) => {
          if (result === null || Array.isArray(result)) {
            return;
          }

          onSelectFile(result);
          setPath(result);
        });
      }}
      className={classNames(boxStyles, {
        "shadow-sm shadow-primary-300 border-primary-300 bg-primary-200/30 text-primary-800 scale-105":
          isDragging,
      })}
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onDragLeave={() => setIsDragging(false)}
      onDrop={(e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file) {
          setIsDragging(false);
        }
      }}
    >
      {innerContent}
    </button>
  );
}

function FileUploader({
  onSelectFile,
}: {
  onSelectFile: (file: DomFile) => void;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [domFile, setDomFile] = useState<DomFile | null>(null);

  const onDomFileSelected = async (domFile: DomFile) => {
    onSelectFile(domFile);
    setDomFile(domFile);
  };

  const handleFileChangeEvent = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      onDomFileSelected(file);
    }
  };

  const boxStyles =
    "block w-full px-6 relative bg-primary-50/50 border-2 border-primary-100 hover:border-primary-300 rounded-lg min-h-[200px] cursor-pointer flex flex-col items-center justify-center text-center text-primary-300 hover:bg-primary-50 hover:text-primary-800 text-xl border-dashed hover:transition break-all";

  const innerContent =
    domFile !== null ? (
      <div>
        <div className="block">
          <span>{domFile.name}</span>
        </div>
        <div className="block">{humanFileSize(domFile.size)}</div>
      </div>
    ) : (
      <div>
        <div className="text-center pb-4 w-full">
          <span className="text-left text-sm">
            <ArrowUpTrayIcon className="inline-block w-8 h-8 mr-1" />
          </span>
        </div>
        <span className="block p-4">Drop a file or click to select a file</span>
      </div>
    );

  return (
    <form className="upload">
      <label
        className={classNames(boxStyles, {
          "shadow-sm shadow-primary-300 border-primary-300 bg-primary-200/30 text-primary-800 scale-105":
            isDragging,
        })}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={() => setIsDragging(false)}
        onDrop={(e) => {
          e.preventDefault();
          const file = e.dataTransfer.files[0];

          if (file) {
            onDomFileSelected(file);
            setIsDragging(false);
          }
        }}
      >
        {innerContent}
        <input
          id="file"
          required
          type="file"
          className="hidden"
          onChange={handleFileChangeEvent}
        />
      </label>
    </form>
  );
}

interface SelectFileProps {
  onChange: (file: FileSource) => void;
  selectedSource: FileSource | null;
  enabledSources: {
    fileUpload: boolean;
    localFile?: boolean;
    url: boolean;
    rawText: boolean;
  };
}

type SourceName = "file" | "local-file" | "url" | "rawText";

export const SelectFile: FC<SelectFileProps> = ({
  onChange,
  selectedSource,
  enabledSources,
}) => {
  const [state, setState] = useState<SelectFileState>({
    fileUpload: { file: null },
    localFile: null,
    urlUpload: { url: "" },
    rawText: { text: "" },
  });

  let defaultSource: SourceName;

  if (enabledSources.localFile) {
    defaultSource = "local-file";
  } else if (enabledSources.fileUpload) {
    defaultSource = "file";
  } else if (enabledSources.url) {
    defaultSource = "url";
  } else if (enabledSources.rawText) {
    defaultSource = "rawText";
  } else {
    throw new Error("No sources are enabled");
  }

  const [currentTab, setCurrentTab] = useState(defaultSource);

  useEffect(() => {
    if (!selectedSource) return;

    setCurrentTab(selectedSource.type);

    switch (selectedSource.type) {
      case "file":
        setState((state) => ({
          ...state,
          fileUpload: { file: selectedSource.file },
        }));
        break;

      case "local-file":
        setState((state) => ({
          ...state,
          localFile: selectedSource.path,
        }));
        break;

      case "url":
        setState((state) => ({
          ...state,
          urlUpload: { url: selectedSource.url },
        }));
        break;

      case "rawText":
        setState((state) => ({
          ...state,
          rawText: { text: selectedSource.text },
        }));
        break;
    }
  }, [selectedSource]);

  const handleDataPaste = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;

    setState((state) => ({
      ...state,
      rawText: { text },
    }));

    onChange({
      type: "rawText",
      text,
    });
  };

  return (
    <div className="w-full">
      <nav
        className="flex flex-col sm:flex-row gap-x-4 gap-y-2"
        aria-label="Tabs"
      >
        {enabledSources.fileUpload && (
          <TabButton
            id="file"
            title="Choose a file"
            currentTab={currentTab}
            setTab={setCurrentTab}
          />
        )}
        {enabledSources.localFile && (
          <TabButton
            id="local-file"
            title="Choose a file"
            currentTab={currentTab}
            setTab={setCurrentTab}
          />
        )}
        {enabledSources.url && (
          <TabButton
            id="url"
            title="Load from URL"
            currentTab={currentTab}
            setTab={setCurrentTab}
          />
        )}
        {enabledSources.rawText && (
          <TabButton
            id="rawText"
            title="Paste data"
            currentTab={currentTab}
            setTab={setCurrentTab}
          />
        )}
      </nav>

      <div className="pt-4 min-h-[230px]">
        {currentTab === "file" && (
          <FileUploader
            onSelectFile={(file) => {
              setState({
                ...state,
                fileUpload: {
                  file: file,
                },
              });

              onChange({
                type: "file",
                file,
              });
            }}
          />
        )}

        {currentTab === "local-file" && (
          <LocalFile
            onSelectFile={(path) => {
              setState({
                ...state,
                localFile: path,
              });

              onChange({
                type: "local-file",
                path,
              });
            }}
          />
        )}

        {currentTab === "url" && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
            }}
          >
            <input
              name="url"
              id="input_url"
              required
              onChange={(e) => {
                setState({
                  ...state,
                  urlUpload: { url: e.target.value },
                });

                onChange({
                  type: "url",
                  url: e.target.value,
                });
              }}
              value={state.urlUpload.url}
              type="url"
              placeholder="https://example.com/data..."
              className="form-control px-4 mt-1 py-2.5"
            />
          </form>
        )}

        {currentTab === "rawText" && (
          <form>
            <textarea
              onChange={handleDataPaste}
              data-gramm_editor="false"
              id="input_raw"
              name="data"
              rows={8}
              value={state.rawText.text}
              className="form-control font-mono p-4"
              placeholder="Paste or type in your data here"
            ></textarea>
          </form>
        )}
      </div>
    </div>
  );
};
